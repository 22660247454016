module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alterpage Agencja Interaktywna","short_name":"Alterpage","start_url":"/","background_color":"#e51f7e","theme_color":"#e51f7e","display":"standalone","icon":"/var/www/digital.alterpage.pl/releases/4/frontend/v2/src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"b8ebb91a973dd736be443c74cbb6bfcc"},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"pl","languages":["pl","en"],"siteUrl":"https://digital2.alterpage.pl","redirect":false,"pagination":{"translations":{"pl":"strona","en":"page"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-42195536-63","cookieName":"AP_ADS_AP","anonymize":false},"facebookPixel":{"pixelId":"638124894314865","cookieName":"AP_ADS_AP"},"linkedin":{"trackingId":"4698753","cookieName":"AP_ADS_AP"},"environments":["development","production"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
